"use client";

import useSWR from "swr";
import axiosClient from "./base";
import {
  CartAddProductProps,
  CartDeleteProps,
  CartItemsProps,
} from "@/type/cart";
import { useMemo } from "react";
import { axiosFetcher } from "./base";
import { getValueFromLocalStorage } from "@/utils/localStorage";

const CART_PRODUCT = (companyId: string) => `/app/company/${companyId}/cart`;

const DELETE_CART_PRODUCT = ({ companyId, cartItemId }: CartDeleteProps) =>
  `/app/company/${companyId}/cart/${cartItemId}`;

const addProductToCart = ({
  companyId,
  productId,
  quantity,
  productConfigs,
}: CartAddProductProps) =>
  axiosClient.post(CART_PRODUCT(companyId), {
    productId,
    quantity,
    productConfigs,
  });

  const getCartItems = (companyId: string) => axiosClient.get(CART_PRODUCT(companyId));  

const deleteCartProduct = ({ companyId, cartItemId }: CartDeleteProps) =>
  axiosClient.delete(DELETE_CART_PRODUCT({ companyId, cartItemId }));

export function useGetCartItems() {
  const user = getValueFromLocalStorage('user');

  const { data, isLoading, error, isValidating } = useSWR(
    user?.user?.companyId ? CART_PRODUCT(user.user.companyId) : null,
    axiosFetcher
  );

  const memoizedValue = useMemo(
    () => ({
      items: (data?.cartItems as CartItemsProps[]) || [],
      itemsLoading: isLoading,
      itemsError: error,
      itemsValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export { addProductToCart, deleteCartProduct, getCartItems };
