"use client";
import React, { useEffect, useState } from "react";
// import { useTranslate } from "@/locales/config-lang";
import { Box, Typography } from "@mui/material";

import ProductDiscountCard from "./product-discount-card";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { useDirectionContext } from "@/context/direction";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Company } from "@/type/company";
import { useTranslation } from "@/app/i18n/client";
import { useGetProducts } from "@/api/products";
import { ProductProps } from "@/type/product";
import LoadingSpinner from "@/components/loading-spinner";

const settings = (lang: string, count: number) => ({
  dots: true,
  className: "center",
  infinite: false,
  speed: 500,
  slidesToShow: count < 4 ? count : 4,
  slidesToScroll: 1,
  nextArrow: lang === "ar" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />,
  prevArrow: lang === "ar" ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
});

export default function HomeOffers({
  company,
  lang,
}: {
  company: Company;
  lang: string;
}) {
  const { t } = useTranslation(lang, "product", {});

  const { products, productsLoading } = useGetProducts();

  const isArabic = lang == "ar";

  const [productsData, setProductsData] = useState<ProductProps[]>([]);

  useEffect(() => {
    if (products.length) {
      const newProducts = products.map((product: ProductProps) => ({
        ...product,
        name: isArabic
          ? product.arName || product.enName
          : product.enName || product.arName,
        subDescription: isArabic
          ? product.arSubDescription || product.enSubDescription
          : product.enSubDescription || product.arSubDescription,
        coverUrl: product.images[0],
      }));
      setProductsData(newProducts);
    }
  }, [isArabic, products]);

  return (
    <Box marginBottom={{ xs: "16px", lg: "90px" }}>
      <Box
        sx={{
          px: { xs: "16px", lg: "80px" },
          py: { xs: "14px", lg: "26px" },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "rgba(245, 245, 245, 0.5)",
        }}
      >
        <Typography
          sx={{
            color: "secondary.main",
            fontSize: { xs: "14px", lg: "24px" },
            fontWeight: "700",
          }}
        >
          {t("offers")}
        </Typography>

        <Typography
          sx={{
            color: "rgba(0, 0, 0, 0.4)",
            textDecoration: "underline",
            fontSize: { xs: "12px", lg: "16x" },
          }}
        >
          {t("explore-offers")}
        </Typography>
      </Box>

      <Box sx={{ mt: { xs: "24px", lg: "64px" }, px: "21px", mb: "40px" }}>
        {productsLoading ? (
          <LoadingSpinner />
        ) : (
          <Slider {...settings(lang, productsData?.length)}>
            {productsData?.map((product, index) => (
              <ProductDiscountCard
                key={index}
                company={company}
                lang={lang}
                product={product}
              />
            ))}
          </Slider>
        )}
      </Box>
    </Box>
  );
}
