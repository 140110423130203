"use client";

import {
  useCallback,
  useContext,
  useMemo,
  createContext,
  useState,
} from "react";

type DirectionsProviderProps = {
  onChangeDirectionByLang: (lang: string) => void;
  themeDirection: string;
};

const initialValue = {
  onChangeDirectionByLang: (lang: string) => {},
  themeDirection: "ltr",
};

const getThemeDirection = () => {
  if (typeof window !== "undefined") {
    const direction = localStorage.getItem("themeDirection");
    return direction ? direction : initialValue.themeDirection;
  } else {
    return initialValue.themeDirection;
  }
};

export const directionContext = createContext<DirectionsProviderProps>(initialValue);

function DirectionProvider({ children }: { children: React.ReactNode }) {
  const [themeDirection, setThemeDirection] = useState(getThemeDirection);

  const onChangeDirectionByLang = useCallback((lang: string) => {
    const direction = lang === "ar" ? "rtl" : "ltr";
    localStorage.setItem("themeDirection", direction);
    setThemeDirection(direction);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      onChangeDirectionByLang,
      themeDirection,
    }),
    [onChangeDirectionByLang, themeDirection]
  );

  return (
    <directionContext.Provider value={memoizedValue}>
      {children}
    </directionContext.Provider>
  );
}

export const useDirectionContext = () => useContext(directionContext);

export { DirectionProvider };
