"use client"

import { useMemo } from "react"
import useSWR from "swr"

import { axiosFetcher } from "./base"
import { GetProductProps, ProductProps } from "@/type/product"

const GET_PRODUCT = ({ productNumber }: GetProductProps) =>
  `/app/product/${productNumber}`;

export function useGetProducts({
  categoryId,
  categorySlug
}: {
  categoryId?: string
  categorySlug?: string
} = {}) {
  const { data, isLoading, error, isValidating } = useSWR(
    categoryId
      ? `app/product?categoryId=${categoryId}`
      : categorySlug
      ? `app/product?categorySlug=${categorySlug}`
      : `app/product`,
    axiosFetcher
  )

  const memoizedValue = useMemo(
    () => ({
      products: (data?.products as ProductProps[]) || [],
      productsLoading: isLoading,
      productsError: error,
      productsValidating: isValidating
    }),
    [data, error, isLoading, isValidating]
  )

  return memoizedValue
}

export function useGetProduct({ productNumber }: GetProductProps) {
  const { data, isLoading, error, isValidating } = useSWR(
    GET_PRODUCT({ productNumber }),
    axiosFetcher
  )

  const memoizedValue = useMemo(
    () => ({
      product: (data as ProductProps) || {},
      productLoading: isLoading,
      productError: error,
      productValidating: isValidating
    }),
    [data, error, isLoading, isValidating]
  )

  return memoizedValue
}
