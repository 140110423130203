export const getSubdomain = () => {
  if (typeof window === "undefined") {
    const host = process.env.HOST || "localhost";
    return host.split(".")[0];
  } else {
    return window.location.hostname.split(".")[0];
  }
};

export const getHostFromHeaders = (headers: Headers): string => {
  return headers.get("host")?.split(":")[0]!;
}

export const getSubdomainFromHost = (host: string) => {
  return host.split(".")[0];
}
