"use client";

import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Button, Typography } from "@mui/material";
import Image from "next/legacy/image";
import { Company } from "@/type/company";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EastIcon from "@mui/icons-material/East";
import { useTranslation } from "@/app/i18n/client";

const settings = (lang: string) => {
  return {
    dots: false,
    className: "center",
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    rtl: lang === "ar" ? true : false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
};

export default function HeroSlider({
  company,
  lang,
}: {
  company: Company;
  lang: string;
}) {
  const { t } = useTranslation(lang, "translation", {});

  const sliderSettings = settings(lang);
  const isRTL = lang === "ar";

  const defaultSlider = (
    <Slider {...sliderSettings} className="hero-slider">
      <Box
        sx={{
          width: "100%",
          height: { xs: "160px", md: "275px", lg: "400px" },
          position: "relative",
          borderRadius: { xs: "0px", lg: "4px" },
          "&:before": {
            content: '" "',
            position: "absolute",
            zIndex: "10",
            inset: 0,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            width: "100%",
            height: "100%",
            borderRadius: { xs: "0px", lg: "4px" },
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            position: "relative",
          }}
        >
          <Image
            src="/images/hero-3.jpeg"
            alt=""
            priority
            style={{ borderRadius: "inherit" }}
            sizes="100%"
            layout="fill"
          />
        </Box>

        <Typography
          sx={{
            position: "absolute",
            zIndex: "10",
            top: "25%",
            left: { xs: "32px", lg: "40px" },
            fontSize: { xs: "18px", lg: "32px" },
            color: "white",
            width: { xs: "60%", lg: "350px" },
            fontWeight: "700",
            textAlign: "left",
          }}
        >
          {t("title1")}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: { xs: "160px", md: "275px", lg: "400px" },
          position: "relative",
          borderRadius: { xs: "0px", lg: "4px" },
          "&:before": {
            content: '" "',
            position: "absolute",
            zIndex: "10",
            inset: 0,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            width: "100%",
            height: "100%",
            borderRadius: { xs: "0px", lg: "4px" },
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            borderRadius: "inherit",
            position: "relative",
          }}
        >
          <Image
            src="/images/hero-2.jpeg"
            alt=""
            priority
            style={{ borderRadius: "inherit" }}
            sizes="100%"
            layout="fill"
          />
        </Box>
        <Typography
          sx={{
            position: "absolute",
            zIndex: "10",
            top: "25%",
            left: { xs: "32px", lg: "40px" },
            fontSize: { xs: "18px", lg: "32px" },
            color: "white",
            width: { xs: "60%", lg: "400px" },
            fontWeight: "700",
            textAlign: "left",
          }}
        >
          {t("title2")}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: { xs: "160px", md: "275px", lg: "400px" },
          position: "relative",
          borderRadius: { xs: "0px", lg: "4px" },
          "&:before": {
            content: '" "',
            position: "absolute",
            zIndex: "10",
            inset: 0,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            width: "100%",
            height: "100%",
            borderRadius: { xs: "0px", lg: "4px" },
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            borderRadius: "inherit",
            position: "relative",
          }}
        >
          <Image
            src="/images/hero-1.jpg"
            alt=""
            style={{ borderRadius: "inherit" }}
            sizes="100%"
            layout="fill"
          />
        </Box>
        <Typography
          sx={{
            position: "absolute",
            zIndex: "10",
            top: "25%",
            left: { xs: "32px", lg: "40px" },
            fontSize: { xs: "18px", lg: "32px" },
            color: "white",
            width: { xs: "60%", lg: "370px" },
            fontWeight: "700",
            textAlign: "left",
          }}
        >
          {t("title3")}
        </Typography>
      </Box>
    </Slider>
  );

  const companyCustomizedSlider = (
    <Slider {...sliderSettings} className="hero-slider">
      {company?.settings?.matjrOriginalProfessionalHomePage?.mainSlider.map(
        (
          slider: { image: string; enTitle: string; buttonLink: string },
          index
        ) => (
          <Box
            key={index}
            sx={{
              width: "100%",
              height: { xs: "160px", md: "275px", lg: "400px" },
              position: "relative",
              borderRadius: { xs: "0px", lg: "4px" },
              "&:before": {
                content: '" "',
                position: "absolute",
                zIndex: "10",
                inset: 0,
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                width: "100%",
                height: "100%",
                borderRadius: { xs: "0px", lg: "4px" },
              },
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                borderRadius: "inherit",
                position: "relative",
              }}
            >
              <Image
                src={slider.image}
                alt=""
                priority
                style={{ borderRadius: "inherit" }}
                sizes="100%"
                layout="fill"
              />
            </Box>
            <Typography
              sx={{
                position: "absolute",
                zIndex: "10",
                top: "25%",
                left: { xs: "32px", lg: "40px" },
                fontSize: { xs: "18px", lg: "32px" },
                color: "white",
                width: { xs: "60%", lg: "370px" },
                fontWeight: "700",
                textAlign: "left",
              }}
            >
              {(slider as any)[`${lang}Title`] || ""}
            </Typography>
            {slider.buttonLink && (
              <Button
                sx={{
                  position: "absolute",
                  bottom: { xs: "20px" },
                  left: { xs: "32px" },
                  width: { xs: "160px", lg: "200px" },
                  height: { xs: "40px", lg: "56px" },
                  borderRadius: "30px",
                  backgroundColor: "primary.main",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                  zIndex: "200",
                  textTransform: "none",
                  boxShadow: "4px 4px 10px 0px rgba(255, 255, 255, 0.2)",
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                }}
                href={slider.buttonLink}
              >
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontSize: { xs: "14px", lg: "16px" },
                  }}
                >
                  {t("search-button")}
                </Typography>

                {!isRTL ? (
                  <EastIcon sx={{ fontSize: "18px" }} />
                ) : (
                  <KeyboardBackspaceIcon />
                )}
              </Button>
            )}
          </Box>
        )
      )}
    </Slider>
  );

  if (company?.settings?.matjrOriginalProfessionalHomePage?.mainSlider.length) {
    return companyCustomizedSlider;
  }

  return defaultSlider;
}
