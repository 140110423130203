import useSWR from "swr";
import { axiosFetcher } from "./base";
import { useMemo } from "react";
import { CategoriesProps } from "@/type/category";
import { getValueFromLocalStorage } from "@/utils/localStorage";


const CATEGORIES_LIST = (companyId: string) => `app/category?company_id=${companyId}`

export function useGetCategories(companyId: number) {

    const { data, isLoading, isValidating, error} = useSWR(companyId ? CATEGORIES_LIST(companyId as any) : null, axiosFetcher)

    const memoizedValue = useMemo(() => ({
        categories: (data?.categories as CategoriesProps[]) || [],
        categoriesLoading: isLoading,
        categoriesError: error,
        categoriesValidating: isValidating,
    }), [data?.categories, error, isLoading, isValidating])

    return memoizedValue;
} 