import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "@/app/i18n/client";

export default function ShopButton({
  lang,
  href,
}: {
  lang: string;
  href?: string;
}) {
  const { t } = useTranslation(lang, "translation", {});
  return (
    <Button
      variant="outlined"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: "0px",
        width: { xs: "88px", lg: "110px" },
        height: { xs: "32px", lg: "44px" },
        borderRadius: "4px",
        fontSize: { xs: "14px", lg: "16px" },
        fontWeight: 700,
        color: "black",
        border: "1px solid black",
        textTransform: "none",
        "&:hover": {
          bgcolor: "primary.main",
          color: "white",
        },
      }}
      href={href || "/"}
    >
      {t("shop-button")}
    </Button>
  );
}
