"use client";

import React, { useEffect, useState } from "react";
import Image from "next/legacy/image";
import { Box, Typography } from "@mui/material";
import { useGetCategories } from "@/api/category";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { useRouter } from "next/navigation";
import { Company } from "@/type/company";
import { useTranslation } from "@/app/i18n/client";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = (lang: string, count: number = 0) => ({
  dots: false,
  // infinite: false,
  // speed: 500,
  // slidesToShow: 6,
  // slidesToScroll: 3,
  // centerPadding: "60px",
  className: "center",
  infinite: true,
  speed: 500,
  slidesToShow: count < 4 ? count : 4,
  slidesToScroll: 1,
  nextArrow: lang === "ar" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />,
  prevArrow: lang === "ar" ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />,
  rtl: lang === "ar",
});

export default function HomeCategories({
  company,
  lang,
}: {
  company: Company;
  lang: string;
}) {
  const router = useRouter();

  const { t } = useTranslation(lang, "translation", {});

  const isArabic = lang === "ar";

  const { categories } = useGetCategories(company?.companyId!);

  const [categoriesData, setCategoriesData] = useState(categories);

  useEffect(() => {
    const newCategories = categories.map((category) => ({
      ...category,
      name: isArabic ? category.arName : category.enName,
    }));

    setCategoriesData(newCategories);
  }, [categories, isArabic]);

  return (
    <Box
      sx={{
        mt: { xs: "32px", lg: "56px" },
        mb: { xs: "24px", lg: "80px" },
        // px: { xs: "16px", lg: "50px" },
      }}
    >
      <Typography
        sx={{
          color: "secondary.main",
          fontSize: { xs: "12px", lg: "20px" },
          fontWeight: { xs: "700", lg: "400" },
          pb: { xs: "16px", lg: "48px" },
          textAlign: "center",
        }}
      >
        {t("categories-search")}
      </Typography>

      <Slider {...settings(lang, categories?.length)}>
        {categoriesData.map((category) => (
          <Box key={category.id}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
              sx={{
                gap: { xs: "8px", lg: "24px" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "50px", lg: "110px" },
                  height: { xs: "50px", lg: "110px" },
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={() => {
                  router.push(
                    category.slug
                      ? `/products?categorySlug=${category.slug}`
                      : `/products?categoryId=${category.id}`
                  );
                }}
              >
                <Image
                  src={category.image}
                  alt=""
                  layout="fill"
                  objectFit="cover"
                  style={{
                    borderRadius: "50%",
                    boxShadow: " 2px 2px 15px 0px rgba(170, 170, 170, 0.15)",
                  }}
                  sizes="(max-width: 768px) 50px, 110px"
                />
              </Box>

              <Typography
                sx={{
                  color: "secondary.main",
                  fontSize: { xs: "12px", lg: "16px" },
                  width: "max-content",
                }}
              >
                {/* {t(`${category.name}`)} */}
                {category.name}
              </Typography>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
