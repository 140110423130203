export const getQuantity = (
  type: string,
  prevQuantity: number | null
): number => {
    if (prevQuantity !== null) {
      if (type === "increment") {
        return prevQuantity + 1;
      } else if (prevQuantity && type === "decrement" && prevQuantity > 1) {
        return prevQuantity - 1;
      } else if (prevQuantity === 0 && type === "increment") {
        return 1;
      } 
    }
    return 1;
};

export const getQuantityOnBlur = (
  itemQuantity: number | null,
) => {
  if (!itemQuantity || itemQuantity < 1) {
    return 1;
  }
  return itemQuantity;
};

export const getQuantityFromInput = (value: string) => {
  const numericValue = Number(value);

  if (!isNaN(numericValue) && numericValue >= 1) {
    return numericValue;
  } 
  return null;
};
