"use client";

import React, { useEffect, useState } from "react";
import { useGetProduct } from "@/api/products";
import { ProductProps } from "@/type/product";
import { useLocales } from "@/locales/config-lang";
import ProductDetails from "../product-details";
import ProProductView from "../../product/pro.view.product";
import LoadingSpinner from "@/components/loading-spinner";
import { Company } from "@/type/company";

type Props = {
  productNumber: number;
  company: Company;
  lang: string;
};

export default function ProductView({ productNumber, company, lang }: Props) {
  // const { currentLang } = useLocales();

  const isArabic = lang === "ar";

  const { product, productLoading } = useGetProduct({ productNumber });

  const [productData, setProductData] = useState<ProductProps>(product);

  useEffect(() => {
    if (product.id) {
      const newProduct = {
        ...product,
        name: isArabic
          ? product.arName || product.enName
          : product.enName || product.arName,
        subDescription: isArabic
          ? product.arSubDescription || product.enSubDescription
          : product.enSubDescription || product.arSubDescription,
        description: isArabic
          ? product.arDescription || product.enDescription
          : product.enDescription || product.arDescription,
        coverUrl: product.images.length !== 0 ? product.images[0] : "",
      };
      setProductData(newProduct);
    }
  }, [isArabic, product]);

  //Get product view version from company.productPage.version
  //for now we hardcode it to pro
  const productViewVersion = "pro";

  if (productLoading) {
    return <LoadingSpinner />;
  }

  if (productViewVersion === "pro") {
    return (
      <ProProductView product={productData} lang={lang} company={company} />
    );
  }
  return <ProductDetails product={productData} />;
}
