"use client";

import React from "react";
import Image from "next/legacy/image";
import { Box, Grid, Typography } from "@mui/material";

import HeroSlider from "./hero-slider";
import ShopButton from "./shop-button";
import { Company } from "@/type/company";
import { useTranslation } from "@/app/i18n/client";

export default function HomeHero({
  company,
  lang,
}: {
  company: Company;
  lang: string;
}) {
  const { t } = useTranslation(lang, "translation", {});

  return (
    <Box sx={{ px: { lg: "40px" } }}>
      <Grid container spacing={{ xs: 2, lg: 4 }}>
        <Grid item xs={12} md={7} position={"relative"}>
          <HeroSlider company={company} lang={lang} />
        </Grid>

        <Grid
          item
          xs={12}
          md={5}
          display={"flex"}
          flexDirection={"column"}
          gap={"16px"}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "4px",
              bgcolor: "rgba(248, 248, 248, 1)",
              p: "16px",
              pl: { xs: "16px", lg: "40px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: "10px", lg: "30px" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "14px", lg: "24px" },
                  width: "90%",
                }}
              >
                {company?.settings?.matjrOriginalProfessionalHomePage?.topBox?.[
                  `${lang}Title` as keyof typeof company.settings.matjrOriginalProfessionalHomePage.topBox
                ] || t("desc1")}
              </Typography>
              <ShopButton
                lang={lang}
                href={
                  company?.settings?.matjrOriginalProfessionalHomePage?.topBox
                    ?.buttonLink
                }
              />
            </Box>

            <Box
              sx={{
                width: { xs: "98px", lg: "170px" },
                height: { xs: "98px", lg: "160px" },
                position: "relative",
              }}
            >
              <Image
                src={
                  company?.settings?.matjrOriginalProfessionalHomePage?.topBox
                    ?.image || "/images/devices.png"
                }
                alt="devices"
                layout="fill"
                sizes="(max-width: 768px) 98px, 170px"
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "4px",
              bgcolor: "rgba(248, 248, 248, 1)",
              p: "16px",
              pl: { xs: "16px", lg: "40px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: "10px", lg: "30px" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "14px", lg: "24px" },
                  width: "80%",
                }}
              >
                {company?.settings?.matjrOriginalProfessionalHomePage
                  ?.bottomBox?.[
                  `${lang}Title` as keyof typeof company.settings.matjrOriginalProfessionalHomePage.topBox
                ] || t("desc2")}
              </Typography>
              <ShopButton
                lang={lang}
                href={
                  company?.settings?.matjrOriginalProfessionalHomePage
                    ?.bottomBox?.buttonLink
                }
              />
            </Box>

            <Box
              sx={{
                width: { xs: "98px", lg: "170px" },
                height: { xs: "98px", lg: "160px" },
                position: "relative",
              }}
            >
              <Image
                src={
                  company?.settings?.matjrOriginalProfessionalHomePage
                    ?.bottomBox?.image || "/images/mobile.jpeg"
                }
                alt="devices"
                layout="fill"
                sizes="(max-width: 768px) 98px, 170px"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
