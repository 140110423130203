"use client";

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { arCurrency, enCurrency, useTranslate } from "@/locales/config-lang";
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import Image from "next/legacy/image";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/Share";
import { ProductProps } from "@/type/product";
import { getValueFromLocalStorage } from "@/utils/localStorage";
import { addProductToCart } from "@/api/cart";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getQuantityOnBlur, getQuantity } from "@/utils/quantityUtils";

type Props = { product: ProductProps };

export default function ProductDetails({ product }: Props) {
  const { t } = useTranslate();

  const company = getValueFromLocalStorage("company");

  const {
    name,
    coverUrl,
    subDescription,
    description,
    images,
    regularPrice,
    sellPrice,
    sku,
    productConfigs,
    id,
    quantity,
  } = product;

  const settings = {
    dots: true,
    infinite: images?.length > 1 && true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    width: "340px",
    height: "300px",
    rtl: true,
  };

  const colors = ["#FFFFFF", "#D9D9D9", "#1A1A1A"];

  const sizes = ["Large", "Medium", "Small"];

  const [imgView, setImgView] = useState("");

  const [itemQuantity, setItemQuantity] = useState<number | null>(quantity);

  useEffect(() => {
    setItemQuantity(quantity);
  }, [quantity]);

  const totalItemsPrice =
    itemQuantity && itemQuantity * Number(sellPrice || regularPrice || 1) + 60;

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const { companyId } = getValueFromLocalStorage("company");

  const handleInputQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    const numericValue = Number(value);

    if (!isNaN(numericValue) && numericValue >= 1) {
      setItemQuantity(numericValue);
    } else if (value === "") {
      setItemQuantity(null);
    }
  };

  const addProductsToCart = async () => {
    try {
      await addProductToCart({
        companyId,
        productId: id,
        quantity: itemQuantity || 1,
        productConfigs,
      });
      toast.success("تم اضافه المنتج لعربة التسوق");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box padding={{ xs: "16px", lg: "40px" }}>
      <Grid container spacing={{ lg: "40px" }}>
        <Grid item xs={12} lg={8.5}>
          <Box
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: {
                xs: "1.5fr 1fr",
                lg: " 1fr repeat(2, auto)",
              },
              columnGap: "40px",
              gridTemplateRows: { lg: " 60px 30px 50px auto  50px" },
              gridTemplateAreas: {
                md: `"image desc favorite"
                      "image rate rate" 
                      "image price price" 
                      "image delivery delivery" 
                      "image info info"
                      "image info info"
                      "image delivery-data delivery-data" `,
                xs: `"image image"
                      "desc favorite"
                      "desc rate"
                       "desc price"
                       "delivery delivery"
                       "info info"
                       "delivery-data delivery-data" `,
              },
            }}
          >
            <Box
              sx={{
                gridArea: "image",
                display: { xs: "none", md: "flex" },
                flexDirection: "column-reverse",
                gap: "32px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "24px",
                  justifyContent: "center",
                }}
              >
                {images?.map((img) => (
                  <Box
                    key={img}
                    sx={{
                      width: "64px",
                      height: "64px",
                      borderRadius: "4px",
                      border:
                        imgView === img
                          ? "2px solid #820000"
                          : "1px solid #0000001A",
                      position: "relative",
                      "&:first-child": {
                        border: !imgView
                          ? "2px solid #820000"
                          : " 1px solid #0000001A",
                      },
                      cursor: "pointer",
                    }}
                    onClick={() => setImgView(img)}
                  >
                    <Image src={img} alt="" layout="fill" objectFit="contain" />
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  border: "1px solid #0000001A",
                  p: "16px",
                  borderRadius: "4px",
                  height: "365px",
                  position: "relative",
                }}
              >
                <Image
                  src={imgView || coverUrl}
                  alt=""
                  layout="fill"
                  objectFit="contain"
                />
              </Box>
            </Box>

            <Box
              sx={{
                gridArea: "image",
                display: { md: "none" },
                mb: "45px",
                justifyContent: "center",
              }}
            >
              {images?.length > 0 && (
                <Slider {...settings} className="img-slider">
                  {images?.map((img) => (
                    <Box
                      key={img}
                      sx={{
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                        position: "relative",
                        width: "375px",
                        height: "280px",
                      }}
                    >
                      <Image
                        src={img}
                        alt=""
                        layout="fill"
                        objectFit="contain"
                      />
                    </Box>
                  ))}
                </Slider>
              )}
            </Box>

            <Box
              className={`block-text ${isMobile ? "text-3" : " text-2"}`}
              sx={{
                gridArea: "desc",
                width: { xs: "95%", lg: "364px" },
                alignSelf: "start",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    lg: "18px",
                    fontWeight: { xs: "400", lg: "700" },
                    pb: "16px",
                  },
                }}
              >
                {subDescription || name}
              </Typography>
            </Box>

            <Box
              sx={{
                gridArea: "favorite",
                display: "flex",
                gap: "20px",
                color: "#959595",
                justifySelf: { xs: "center", lg: "end" },
                mb: "12px",
              }}
            >
              <FavoriteBorderIcon />
              <ShareIcon />
            </Box>

            <Box
              sx={{
                gridArea: "rate",
                height: "19px",
                justifySelf: { xs: "center", md: "start" },
                alignSelf: "start",
                mb: "12px",
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <StarRateRoundedIcon
                  sx={{
                    color: "rgba(234, 168, 0, 1)",
                    width: "16px",
                    height: "16px",
                  }}
                />
                <StarRateRoundedIcon
                  sx={{
                    color: "rgba(234, 168, 0, 1)",
                    width: "16px",
                    height: "16px",
                  }}
                />
                <StarRateRoundedIcon
                  sx={{
                    color: "rgba(234, 168, 0, 1)",
                    width: "16px",
                    height: "16px",
                  }}
                />
                <StarOutlineRoundedIcon
                  sx={{
                    color: "rgba(0, 0, 0, 0.4)",
                    width: "16px",
                    height: "16px",
                  }}
                />
                <StarOutlineRoundedIcon
                  sx={{
                    color: "rgba(0, 0, 0, 0.4)",
                    width: "16px",
                    height: "16px",
                  }}
                />

                <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  {"(5)"}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                gridArea: "price",
                minHeight: "27px",
                justifySelf: { xs: "center", md: "start" },
                alignSelf: "start",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "20px", lg: "24px" },
                  fontWeight: "bold",
                }}
              >
                {` ${regularPrice || sellPrice || ""} ${
                  !company?.currency ? enCurrency : arCurrency
                } `}
              </Typography>
            </Box>

            <Box
              sx={{
                gridArea: "info",
                height: "210px",
                backgroundColor: "secondaryBackground",
                mt: { lg: "15px" },
                mb: { lg: "16px" },
                display: "grid",
                gridTemplateAreas: "repeat(2, 1fr)",
                px: { xs: "32px", lg: "24px" },
                py: "24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  height: "40px",
                  alignItems: "flex-start",
                }}
              >
                <Image
                  src={"/images/model_icon.svg"}
                  alt=""
                  width={20}
                  height={16}
                />
                <Box>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: "300", mb: "8px" }}
                  >
                    {t("model-number")}
                  </Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
                    {sku}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                gridArea: "delivery",
                display: "flex",
                gap: "16px",
                alignItems: "center",
                height: "16px",
                alignSelf: "start",
                mt: { xs: "16px", lg: "unset" },
                mb: { xs: "16px", lg: "unset" },
              }}
            >
              <Image src={"/images/cart.svg"} alt="" width={24} height={16} />
              <Typography sx={{ fontSize: "14px" }}>
                يتم الشحن خلال 4 أيام
              </Typography>
            </Box>

            <Box
              sx={{
                gridArea: "delivery-data",
                height: "max-content",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "4px",
                mt: { xs: "16px", lg: "0px" },
                backgroundColor: "secondaryBackground",
                px: "16px",
                py: "8px",
                mb: { xs: "16px", lg: "unset" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  justifyContent: "center",
                }}
              >
                <Image
                  src={"/images/security icon.svg"}
                  alt=""
                  width={14}
                  height={16}
                />
                <Typography sx={{ fontSize: "12px", color: "secondary.main" }}>
                  {t("3-years-warranty")}
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "#959595" }}>
                  {t("full-coverage")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  justifyContent: "center",
                }}
              >
                <Image src={"/images/cash.svg"} alt="" width={20} height={16} />
                <Typography sx={{ fontSize: "12px", color: "secondary.main" }}>
                  {t("paiement-when-recieving")}
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "#959595" }}>
                  {t("cash-on-delivery")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  justifyContent: "center",
                }}
              >
                <Image
                  src={"/images/replace.svg"}
                  alt=""
                  width={17}
                  height={16}
                />
                <Typography sx={{ fontSize: "12px", color: "secondary.main" }}>
                  {t("free-returns")}
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "#959595" }}>
                  {t("within-15-days")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} lg={3.5}>
          <Box
            sx={{
              px: { xs: "16px", lg: "32px" },
              py: { xs: "16px", lg: "24px" },
              boxShadow: {
                xs: " 0px 0px 10px -2px #8200000A",
                lg: "2px 2px 15px 0px #AAAAAA26",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", lg: "column" },
                gap: { lg: "8px" },
                alignItems: { xs: "center", lg: "flex-start" },
                justifyContent: "space-between",
                mb: "20px",
              }}
            >
              <Typography sx={{ fontSize: "14px" }}>{t("quantity")}</Typography>

              <Box
                sx={{
                  display: "flex",
                  height: { xs: "40px", lg: "48px" },
                  width: { xs: "60%", lg: "100%" },
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  sx={{
                    px: "0px",
                    minWidth: { xs: "48px", lg: "25%" },
                    textAlign: "center",
                    backgroundColor: "primary.main",
                    borderRadius: "0px",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                  onClick={() =>
                    setItemQuantity(getQuantity("increment", itemQuantity))
                  }
                >
                  <AddOutlinedIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "white",
                    }}
                  />
                </Button>

                <TextField
                  value={itemQuantity}
                  type="number"
                  onChange={handleInputQuantityChange}
                  onBlur={() =>
                    setItemQuantity(getQuantityOnBlur(itemQuantity))
                  }
                  InputProps={{
                    sx: {
                      "& input": {
                        textAlign: "center",
                      },
                    },
                  }}
                  sx={{
                    width: { width: "100px", lg: "50%" },
                    backgroundColor: "secondaryBackground",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: "0px",
                    "& .MuiInputBase-root": {
                      height: "100%",
                      px: "0px",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />

                <Button
                  sx={{
                    minWidth: { xs: "48px", lg: "25%" },
                    textAlign: "center",
                    backgroundColor: "#EEEEEE",
                    borderRadius: "0px",
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#EEEEEE",
                    },
                  }}
                  onClick={() =>
                    setItemQuantity(getQuantity("decrement", itemQuantity))
                  }
                >
                  <RemoveOutlinedIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "third",
                    }}
                  />
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                alignItems: { lg: "flex-start" },
                justifyContent: "space-between",
                mb: "20px",
              }}
            >
              <Typography sx={{ fontSize: "14px" }}>{t("color")}</Typography>
              <Box sx={{ display: "flex", gap: "12px" }}>
                {colors.map((color) => (
                  <Box
                    key={color}
                    sx={{
                      border: "1px solid #F5F5F5",
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      backgroundColor: color,
                      cursor: "pointer",
                      "&:hover": {
                        border: "1px solid",
                        borderColor: "primary.main",
                      },
                    }}
                  ></Box>
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                alignItems: { lg: "flex-start" },
                justifyContent: "space-between",
                mb: "20px",
              }}
            >
              <Typography sx={{ fontSize: "14px" }}>{t("size")}</Typography>
              <Box sx={{ display: "flex", gap: "12px" }}>
                {sizes.map((size) => (
                  <Box
                    key={size}
                    sx={{
                      px: "10px",
                      py: "5px",
                      backgroundColor: "secondaryBackground",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px", color: "#656565" }}>
                      {size}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: { xs: "26px", lg: "28px" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: { xs: "#656565", lg: "secondary.main" },
                    fontWeight: { xs: "700", lg: "400" },
                  }}
                >
                  {t("price")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", lg: "18px" },
                    color: "secondary.main",
                    fontWeight: "700",
                  }}
                >
                  {` ${regularPrice || sellPrice || ""} ${
                    !company?.currency ? enCurrency : arCurrency
                  } `}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: { xs: "#656565", lg: "secondary.main" },
                    fontWeight: { xs: "700", lg: "400" },
                  }}
                >
                  {t("delivery")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", lg: "18px" },
                    color: { xs: "#656565", lg: "secondary.main" },
                  }}
                >
                  {`60 ${!company?.currency ? enCurrency : arCurrency}`}
                </Typography>
              </Box>

              <Divider
                sx={{
                  my: "24px",
                  backgroundColor: "#0000001A",
                  height: "1px",
                  width: "100%",
                }}
              />

              <Box
                sx={{
                  mb: "40px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: { xs: "#656565", lg: "secondary.main" },
                  }}
                >
                  {t("total")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", lg: "20px" },
                    color: "secondary.main",
                    fontWeight: "700",
                  }}
                >
                  {`${totalItemsPrice} ${
                    !company?.currency ? enCurrency : arCurrency
                  }`}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Button
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "4px",
                  backgroundColor: "primary.main",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  textTransform: "none",
                  "&:hover": {
                    bgcolor: "primary.main",
                  },
                }}
                onClick={addProductsToCart}
              >
                <AddShoppingCartIcon sx={{ fontSize: "16px" }} />
                <Typography sx={{ fontSize: "16px" }}>
                  {t("add-cart")}
                </Typography>
              </Button>

              <Button
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "4px",
                  border: "2px solid",
                  borderColor: "primary.main",
                  color: "primary.main",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                <Image
                  src={"/images/buyIcon.svg"}
                  alt=""
                  width={16}
                  height={16}
                />
                <Typography sx={{ fontSize: "16px" }}>
                  {t("buy-now")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} mt={{ lg: "46px" }}>
          <Box
            sx={{
              borderBottom: "1px solid #F0F0F0",
              width: "100%",
              mb: { xs: "24px", lg: "56px" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", lg: "16px" },
                fontWeight: { xs: "400", lg: "700" },
                borderBottom: "3px solid #820000",
                pb: { xs: "8px", lg: "25px" },
                width: "max-content",
              }}
            >
              {t("product-details")}
            </Typography>
          </Box>

          <Typography
            sx={{
              fontSize: { xs: "14px", lg: "16px" },
              width: { xs: "90%", lg: "80%" },
              lineHeight: "2",
              ml: { xs: "16px", lg: "unset" },
            }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
