import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

export default function LoadingSpinner () {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress sx={{ color: '#820000'}}  />
    </Box>

  )
}
