//@deprecated
// This file is deprecated and will be removed in the future.
//USE app/i18n instead


import { useDirectionContext } from "@/context/direction";
import { getValueFromLocalStorage } from "@/utils/localStorage";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const arCurrency = "ج.م";
export const enCurrency = "L.E";

const company = getValueFromLocalStorage('company')

export const allLangs = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Arabic",
    value: "ar",
  },
];


export const defaultLang = company?.defaultLanguage || allLangs[0];

export function useLocales() {
  let langStorage: any = undefined;

  if (typeof window !== "undefined") {
    langStorage = localStorage.getItem("i18nextLng");
  }
  const currentLang =
    allLangs.find((lang) => lang.value === langStorage) || defaultLang;

  return {
    allLangs,
    currentLang,
  };
}

export function useTranslate() {
  const { t, i18n } = useTranslation();

  const { onChangeDirectionByLang } = useDirectionContext();

  const onChangeLang = useCallback(
    (newLang: string) => {
      onChangeDirectionByLang(newLang);
      i18n.changeLanguage(newLang);
    },
    [i18n, onChangeDirectionByLang]
  );

  return {
    t,
    i18n,
    onChangeLang,
  };
}
